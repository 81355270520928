@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("../../../node_modules/antd/dist/antd.compact.css");
@import url("./_shimmer.scss");
@import url("./_versus.scss");
@font-face {
    font-family: "Vazir";
    src: url("../../assets/fonts/vazir/Vazir.woff2") format("woff2");
}
@font-face {
    font-family: "Vazir-with-farsi-digits";
    src: url("../../assets/fonts/vazir/farsi-digits-without-latin/Vazir-FD-WOL.woff2");
}

@layer components {
    .btn-purchase-purple {
        @apply bg-gradient-to-r from-purple-600 to-purple-500 rounded-2xl mt-auto h-10 border-0 text-white #{!important};
        @apply hover:opacity-80 hover:bg-gradient-to-r hover:from-purple-600 hover:to-purple-500  #{!important};
        @apply focus:opacity-80 focus:bg-gradient-to-r focus:from-purple-600 focus:to-purple-500  #{!important};
    }
    .btn-purchase-gold {
        @apply bg-gradient-to-r from-orange-500 to-yellow-600 rounded-2xl mt-auto h-10 border-0 text-white #{!important};
        @apply hover:opacity-80 hover:bg-gradient-to-r hover:from-orange-500 hover:to-yellow-600 #{!important};
        @apply focus:opacity-80 focus:bg-gradient-to-r focus:from-orange-500 focus:to-yellow-600 #{!important};
    }
    .btn-purchase-success {
        @apply bg-gradient-to-r from-green-500 to-green-600 rounded-2xl mt-auto h-12 border-0 text-white #{!important};
        @apply hover:opacity-80 hover:bg-gradient-to-r hover:from-green-500 hover:to-green-600  #{!important};
        @apply focus:opacity-80 focus:bg-gradient-to-r focus:from-green-500 focus:to-green-600  #{!important};
    }
    .btn-purchase-danger {
        @apply bg-gradient-to-r from-red-500 to-red-600 rounded-2xl mt-auto h-12 border-0 text-white #{!important};
        @apply hover:opacity-80 hover:bg-gradient-to-r hover:from-red-500 hover:to-red-600  #{!important};
        @apply focus:opacity-80 focus:bg-gradient-to-r focus:from-red-500 focus:to-red-600  #{!important};
    }
}

#root {
    @apply lg:mx-auto lg:my-auto lg:w-[430px] relative;
}

* {
    color: #fff;
    font-family: "Montserrat" !important;
}

body {
    background-color: #192038;
}

.ltr {
    direction: ltr !important;
}

.rtl {
    direction: rtl !important;
}

.everything-ltr {
    direction: ltr;
    * {
        direction: ltr;
    }
}

.everything-rtl {
    direction: rtl;
    * {
        direction: rtl;
    }
}

* {
    &::-webkit-scrollbar {
        height: 0px;
        width: 0px;
    }

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.offline-modal {
    .ant-modal-content {
        background-color: #28345a !important;
    }
}

.nsm7Bb-HzV7m-LgbsSe {
    background-color: transparent !important;
}

.font-en {
    font-family: "Heebo" !important;
}

// Begin: Shimmer
.offer-card-skeleton {
    @apply rounded-lg;
    height: 9rem !important;
    width: 18rem !important;
}

.w-full-important {
    width: 100% !important;
}

.h-full-important {
    height: 100% !important;
}

.ant-skeleton-button-sm {
    height: 16px !important;
}

.activity-card-icon {
    border-radius: 0.75rem !important;
    min-width: 55.66px !important;
    min-height: 55.66px !important;
}

.ant-skeleton-button-lg {
    height: 3rem !important;
    min-width: 3rem !important;
}

.ant-skeleton-avatar {
    width: 100% !important;
    height: 100% !important;
}
// End: Shimmer

.swiper {
    // width: 100% !important;
    // height: 100% !important;
}

.swiper-wrapper {
    width: 100vw !important;
    height: 100vh !important;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
}

.custom-modal {
    .ant-modal-content {
        background-color: #192038 !important;
    }
}

.ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.8) !important;
}
