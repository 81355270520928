.versus {
    &-bg-down-shape {
        clip-path: polygon(0% 65%, 100% 35%, 100% 100%, 0% 100%);
    }
}

.animate-in-from-tl {
    animation: animateInTopLeft 250ms ease-out forwards;
}

.animate-in-from-br {
    animation: animateInBottomRight 250ms ease-out forwards;
}

.flip {
    transform: rotateY(180deg);
}

.flip-infinite {
    animation: flip 2s ease-in-out 1s infinite;
}

@keyframes flip {
    0% {
        transform: rotateY(0);
    }
    30% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(180deg);
    }
}

@keyframes animateInTopLeft {
    from {
        transform: translate(-100%, -100%);
    }

    to {
        transform: translate(0, 0);
    }
}

@keyframes animateInBottomRight {
    from {
        transform: translate(100%, 100%);
    }
    to {
        transform: translate(0, 0);
    }
}
